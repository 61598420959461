<template>
    <ol class="chapters-list">
        <li v-for="(chapter, index) in chapters" :key="chapter.id" :class="{ 'in-view': chapter.isInView }">
            <Anchor :url="`#${titleToSlug(chapter.label)}`" class="link chapter-sidebar-toc">
                <Typography as="div" variant="label" class="number">{{ getChapterNumber(index) }}</Typography>
                <Typography as="div" variant="body-large" class="title">{{ chapter.label }}</Typography>
            </Anchor>
        </li>
    </ol>
</template>

<script setup>
const props = defineProps({
    chapters: {
        type: Array,
        default: () => [],
    },
});

const getChapterNumber = (index) => {
    return index < 10 ? `0${index}` : index;
};
</script>

<style lang="scss" scoped>
.chapters-list {
    display: flex;
    flex-direction: column;
    gap: 1.7rem;
}

.link {
    display: flex;
    gap: 0.4rem;
    color: color(blue, default, 0.6);

    .number,
    .title {
        @include transition(color, controls);
    }

    .number {
        color: color(blue, dark, 0.6);
    }

    &:hover,
    .in-view & {
        .number,
        .title {
            color: color(teal);
        }
    }
    &:active,
    .in-view & {
        .number,
        .title {
            color: color(blue, active);
        }
    }
}

.title {
    margin-top: calc(3 / 16 * -1em);
}
</style>
